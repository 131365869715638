import { environment as defaultEnvironment } from './environment.default';

export const environment = {
  ...defaultEnvironment,

  platform: "dela", //see isRivalCasino() in configService
  // isXmasOnly: true,
  isLandingsOnly: true,

  rivalName: "betrophy",
  casinoId: 0,
  prettyName: "Betrophy", //as we call it

  rivalChatGroupName: "Betrophy",
  publicPromotions: [],
  affiliatedGames: [],

  //todo Google Analitics
  GA_TRACKING: "",


  //SEO
  metaDescription: "Betrophy Online Casino Games with free slots, sports betting and video poker. Win trophy by free spins, bet with no deposit bonus, get betrophy welcome bonuses.",
  logoName: "betrophy-online-casino-logo.png",
  logoAltText: "Betrophy Casino Logo featuring bold text with a sleek, modern design, emphasizing Online Sports Betting, Live Dealer Games, Real Money Betting, and Virtual Sports",


};


